import {Paper, Box, Divider, IconButton, Typography, List, ListItem, ListItemText} from '@mui/material';
import Share from './Share';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {useNavigate} from 'react-router-dom';

const SearchResults = (props) => {
    const items = props.items;
    const navigate = useNavigate();
    const handleClick = (event, url) => {
        event.preventDefault();
        navigate(url);
    }
    return (<>
        {items !== undefined && items.map((item, index) => (<Paper sx={{my:2}} key={"result-"+index}>
            <List dense={true} sx={{p:2}}>
                <ListItem>
                    <ListItemText primary={<strong>Nombre</strong>} secondary={(<Typography variant="body1" textTransform="capitalize">{item.nombre.toLowerCase()}</Typography>)} / >
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={<strong>RUT</strong>} secondary={(<Typography variant="body1" textTransform="capitalize">{item.rut}</Typography>)} />
                    </ListItem>
                </List>
                <Divider />
                <Box display="flex" justifyContent="flex-end" sx={{p:1}}>
                    <Share data={item.url} />
                    <IconButton onClick={(event) => handleClick(event, item.path)}>
                        <VisibilityIcon />
                    </IconButton>
                </Box>
                </Paper>))}
                </>);
                }

                export default SearchResults;
