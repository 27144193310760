const endpoint = 'https://api.rutifikador.com/enterprise/page';

export const post = (rut, name_slug) => {
    const options = {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({rut: rut, name_slug: name_slug}),
    };
    return fetch(endpoint, options).then((response) => response.json());
}
