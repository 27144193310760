const endpoint = 'https://api.rutifikador.com/enterprise/search';

export const post = (newValue) => {
    const options = {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({'q': newValue}), 
    };
    return fetch(endpoint, options).then((response) => response.json());
}
