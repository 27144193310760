import {Paper, Stack, Typography} from '@mui/material';

const SearchResults = (props) => {
    const info = props.info;
    return (<>{info !== undefined && (<Paper sx={{mt:2, p:2}}>
            <Stack direction="row" display="flex" justifyContent="space-between">
                <Typography>{info}</Typography>
            </Stack>
            </Paper>)}
            </>);
}

export default SearchResults;
