import {Paper, Stack} from '@mui/material';
import WomanIcon from '@mui/icons-material/Woman';
import FemaleIcon from '@mui/icons-material/Female';

const Woman = () => {
    return (<Paper elevation={3} sx={{p:4, mt:2}}>
        <Stack direction="row" display="flex" alignItems="center" justifyContent="space-around">
            <WomanIcon sx={{ fontSize: 100, color: '#ff8da1' }}/>
            <FemaleIcon sx={{ fontSize: 100, color: '#ff8da1' }}/>
        </Stack>
        </Paper>);
}

export default Woman;
