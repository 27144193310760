import {useState} from 'react';
import {Stack, Box, IconButton, Typography} from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

const Share = (props) => {
    const [copied, setCopied] = useState(false);
    const data = props.data;

    const handleClick = () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 5000);
        navigator.clipboard.writeText(data);
    }

    return (<Stack direction="row">{copied && (<Box display="flex" alignItems="center">
    <Typography variant="body2">Copiado!</Typography>
    </Box>)}
    <IconButton size="large" onClick={() => handleClick()}>{!copied ? (<ShareIcon />) : (<AssignmentTurnedInIcon color="success" />)}
    </IconButton></Stack>);
}

export default Share;
