import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {Stack, Box, Button } from '@mui/material';
import {useNavigate} from 'react-router-dom';
import logo from '../logo.png';
const BackButton = () => {
    const navigate = useNavigate();
    const handleClick = (event) => {
        event.preventDefault()
        navigate('/');
    }
    return (<Stack direction="row" display="flex" justifyContent="space-between" sx={{mt:2}}>
        <Button onClick={(event) => handleClick(event)}>
            <ChevronLeftIcon sx={{fontSize: "34px"}} />
        </Button>
        <Box display="flex" justifyContent="flex-end">
            <Button sx={{width:150, p:0, m:0}} onClick={(event) => handleClick(event)}>
            <img width="100%" src={logo} alt="Rutifikador" />
            </Button>
            </Box>
        </Stack>);
}

export default BackButton;
