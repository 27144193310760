import {useEffect, useState} from 'react';
import {Container, Paper, Box, Grid, TextField} from '@mui/material';
import {post} from './api/search';
import SearchResults from './SearchResults';
import SearchInfo from './SearchInfo';
import logo from '../logo.png';
import {Helmet} from 'react-helmet';
import {useSearchParams} from 'react-router-dom';

const Search = () => {
    const [loaded, setLoaded] = useState(false);
    const [items, setItems] = useState(undefined);
    const [info, setInfo] = useState(undefined);
    const [lastValue, setLastValue] = useState('');
    const [prevValue, setPrevValue] = useState('');
    const [searchParams] = useSearchParams();

    const handleChange = (newValue) => {
        setLastValue(newValue);
    }
    useEffect(() => {
        const idx = setInterval(() => {
            if(lastValue !== prevValue) {
                setPrevValue(lastValue);
                if(lastValue.length > 0) {
                    post(lastValue).then(result => {
                        if(result.status === 'success' && result.data.length > 0) {
                            setItems(result.data);
                            setInfo(undefined);
                        } else if(result.status === 'info') {
                            setInfo(result.message);
                            setItems(undefined);
                        } else {
                            setItems(undefined);
                            setInfo("Error desconocido");
                        }
                    });
                }
            }
            if(lastValue === "") {
                setItems(undefined);
                setInfo(undefined);
            }
        }, 250);

        return () => { clearInterval(idx) };
    }, [lastValue, prevValue]);

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            const query = searchParams.get('q');
            if(query !== null && query.length > 0) {
                setTimeout(() => {setLastValue(query);}, 50);
            }
        }
    }, [loaded, searchParams]);
    return (<Container maxWidth="sm">
        <Helmet>
            <title>Rutifikador | Buscador de Nombre y RUT</title>
            <meta name="description" content="Buscador. Herramienta para encontrar datos. RUT y Nombre." />
            <meta name="keywords" content="nombre, rut, rutifikador, rutificador" />
        </Helmet>

        <Box align="center">
            <img src={logo} alt="Rutifikador" />
        </Box>
        <Paper component="form" noValidate onSubmit={(e) => {e.preventDefault()}} sx={{mt:2, p:2}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                    autoComplete="off"
                    name="field"
                    value={lastValue}
                    onChange={(event) => handleChange(event.target.value)}
                    fullWidth
                    id="field"
                    label="Buscar por Nombre o RUT"
                    autoFocus />
                </Grid>
            </Grid>
        </Paper>
        <SearchResults items={items} />
        {items === undefined && (<SearchInfo info={info} />)}
        </Container>);
}

export default Search;
