import {Paper, Box, Stack, Typography, Divider} from '@mui/material';
import Copy from './Copy';
const Nombre = (props) => {
    const nombre = props.nombre;

    return (<Paper sx={{mt:2}} align="center" display="flex" justifyContent="center">
        <Stack sx={{p:2}} display="flex" justifyContent="center">
            <Typography variant="h6">Nombre</Typography>
            <Typography fontSize="large" textTransform="capitalize">{nombre.toLowerCase()}</Typography>
        </Stack>
        <Divider />
        <Box display="flex" justifyContent="flex-end" sx={{p:1}}>
        <Copy data={nombre} />
        </Box>
        </Paper>);
}

export default Nombre;
