import {Paper, Box, Stack, Typography, Divider} from '@mui/material';
import Copy from './Copy';
const RUT = (props) => {
    const rut = props.rut;

    return (<Paper sx={{mt:2}} align="center">
        <Stack sx={{p:2}}>
            <Typography variant="h6">RUT</Typography>
            <Typography fontSize="large">{rut}</Typography>
        </Stack>
        <Divider />
        <Box display="flex" justifyContent="flex-end" sx={{p:1}}>
            <Copy data={rut} />
        </Box>
        </Paper>);
}

export default RUT;
