import {Paper, Stack} from '@mui/material';
import ManIcon from '@mui/icons-material/Man';
import MaleIcon from '@mui/icons-material/Male';

const Woman = () => {
    return (<Paper elevation={3} sx={{p:4, mt:2}}>
        <Stack direction="row" display="flex" alignItems="center" justifyContent="space-around">
            <ManIcon sx={{fontSize: 100, color: "#3a5874"}} />
            <MaleIcon sx={{fontSize: 100, color: "#3a5874"}} />
        </Stack>
        </Paper>);
}

export default Woman;
